import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import ClickOutsideHandler from 'react-outside-click-handler';
import { useKey } from 'react-use';
import NavItem from 'components/atoms/NavItem';
import Nav from 'components/molecules/Nav';
import ProductsNav from 'components/molecules/ProductsNav';

interface ProductsMenuProps {
  onClose: () => void;
}

const ProductsMenu: React.FC<ProductsMenuProps> = ({ onClose }) => {
  useKey('Escape', onClose);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'tween', duration: 0.3 }}
        className="fixed top-0 bottom-0 left-0 right-0 z-30 bg-black bg-opacity-50 text-dark-grey"
      />

      <ClickOutsideHandler onOutsideClick={onClose}>
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: 'auto' }}
          exit={{ width: 0 }}
          transition={{ type: 'tween', duration: 0.3 }}
          className="fixed top-0 bottom-0 left-0 z-40 flex flex-col justify-between max-w-full overflow-hidden overflow-y-auto bg-white scrollbars-hidden"
          onMouseLeave={onClose}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="pr-12 pl-md"
            style={{ minWidth: '24rem' }}
          >
            <div className="flex items-center h-16 mb-8 md:h-xl">
              <Nav className="flex-1 hidden lg:flex whitespace-nowrap">
                <NavItem to="#" label="Products" />
                <NavItem to="/resources" label="Resources" />
                <NavItem
                  className="mr-0"
                  to="https://support.skupos.com/"
                  label="Help Center"
                />
              </Nav>
            </div>

            <ProductsNav onClick={onClose} />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="py-8 pr-16 mt-8 pl-md"
          >
            <Link
              className="block py-3 text-lg font-semibold text-center border border-gray-200 rounded-full whitespace-nowrap"
              to="/get-started"
              onClick={onClose}
            >
              Get started
            </Link>
          </motion.div>
        </motion.div>
      </ClickOutsideHandler>
    </>
  );
};

export default ProductsMenu;
