import { Link } from 'gatsby';
import React, { useMemo } from 'react';

interface LogoProps {
  dark?: boolean;
  inverted?: boolean;
}

const Logo: React.FC<LogoProps> = ({ dark, inverted }) => {
  const logoUrl = useMemo(() => {
    if (dark) {
      return '/images/skupos-logo-dark.svg';
    }

    if (inverted) {
      return '/images/skupos-logo-white.svg';
    }

    return '/images/skupos-logo.svg';
  }, [dark, inverted]);

  return (
    <Link to="/" className="focus:outline-none focus:ring-0">
      <img className="h-6 md:h-7 -mb-1 w-auto hover:opacity-75 transition-opacity duration-150 ease-in-out" src={logoUrl} alt="Skupos" />
    </Link>
  );
};

export default Logo;
