import { Link } from 'gatsby';
import React from 'react';
import Logo from 'components/atoms/Logo';
import SocialIcon from 'components/atoms/SocialIcon';
import moment from 'moment';

const Footer: React.FC = () => {
  return (
    <footer className="px-6 lg:px-md py-12 md:py-16 bg-dark-grey relative">
      <div className="u-container mx-auto">
        <div className="mb-8 flex justify-center md:justify-start">
          <Logo dark />
        </div>

        <div className="flex flex-col items-center justify-between text-xs font-bold text-white uppercase md:flex-row">
          <div className="flex flex-col items-center md:flex-row">
            <div className="md:mr-6">
              © {moment().format('YYYY')} Skupos. All Rights Reserved.{' '}
            </div>
            <div className="flex items-center mt-6 md:mt-0">
              <Link
                to="/terms"
                className="mr-6 transition-opacity hover:opacity-75 duration-150 ease-in-out"
              >
                Terms
              </Link>
              <Link
                to="/privacy"
                className="transition-opacity hover:opacity-75 duration-150 ease-in-out mr-6"
              >
                Privacy
              </Link>
            </div>
          </div>
          <div className="flex items-center mt-6 md:mt-0 space-x-6">
            <SocialIcon url="https://www.facebook.com/skupos" type="facebook" />
            <SocialIcon url="https://twitter.com/skupos" type="twitter" />
            <SocialIcon
              url="https://www.linkedin.com/company/skupos-inc."
              type="linkedin"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
