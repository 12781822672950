import React, { useState, useEffect, useRef, useCallback } from 'react';
import Logo from 'components/atoms/Logo';
import MenuToggle from 'components/atoms/MenuToggle';
import NavCta from 'components/atoms/NavCta';
import NavItem from 'components/atoms/NavItem';
import Nav from 'components/molecules/Nav';

interface HeaderProps {
  siteTitle: string;
  background: string;
  borderless?: boolean;
  inverted?: boolean;
  position?: string;
  isMobileMenuOpen?: boolean;
  onProductsMenuOpen?: () => void;
  onMobileMenuToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({
  position = 'fixed',
  borderless,
  inverted,
  isMobileMenuOpen,
  onProductsMenuOpen,
  onMobileMenuToggle,
}) => {
  const headerRef = useRef(null);
  const [navScrolled, setNavScrolled] = useState(false);
  const [computedInverted, setComputedInverted] = useState(inverted);

  const handleScroll = useCallback(() => {
    const header = headerRef.current;
    if (!header) return;
    setNavScrolled(
      document.body.scrollTop > 0 || document.documentElement.scrollTop > 0,
    );
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (inverted) {
      setComputedInverted(!navScrolled);
    }
  }, [navScrolled]);

  return (
    <header className={`top-0 left-0 right-0 z-20 bg-transparent ${position}`}>
      <div
        ref={headerRef}
        className={`flex items-center justify-between h-16 px-6 lg:px-md border-opacity-10 md:h-xl bg-transparent transition-colors duration-200
        ${navScrolled ? 'bg-white' : ''}
        ${borderless ? '' : 'border-b'}
        ${
          computedInverted
            ? 'border-white text-white'
            : 'border-dark-grey text-dark-grey'
        }`}
      >
        <Nav className="flex-1 hidden lg:flex">
          <NavItem
            to="#"
            label="Products"
            onClick={onProductsMenuOpen}
            onMouseEnter={onProductsMenuOpen}
          />
          <NavItem to="/resources" label="Resources" />
          <NavItem to="https://support.skupos.com/" label="Help Center" />
        </Nav>
        <Logo inverted={computedInverted} />
        <div className="items-center justify-end flex-1 hidden lg:flex">
          <NavItem to="https://go.skupos.com" label="Log in" />
          <NavCta to="/get-started" label="Get started" />
        </div>
        <MenuToggle open={isMobileMenuOpen} onToggle={onMobileMenuToggle} />
      </div>
    </header>
  );
};

export default Header;
