import { Link } from 'gatsby';
import React from 'react';

interface NavCtaProps {
  to: string;
  label: string;
}

const NavCta: React.FC<NavCtaProps> = ({ to, label }) => {
  return (
    <Link
      to={to}
      className="inline-flex items-center h-12 px-6 font-semibold text-white rounded-full bg-primary hover:bg-dark-grey transition-colors duration-150 ease-in-out"
    >
      {label}
    </Link>
  );
};

export default NavCta;
