import React from 'react';

interface MenuToggleProps {
  open?: boolean;
  onToggle: () => void;
}

const MenuToggle: React.FC<MenuToggleProps> = ({ open, onToggle }) => {
  return (
    <div className="-my-2 lg:hidden">
      <button
        type="button"
        className={`w-9 h-9 inline-flex items-center justify-center p-2 border-gray-300 border rounded-full hover:text-primary hover:bg-gray-100 focus:outline-none focus:ring-0 ${
          open ? 'bg-primary text-white' : 'bg-transparent text-gray-400'
        }`}
        onClick={onToggle}
      >
        <span className="sr-only">Open menu</span>
        {open ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              fill="currentColor"
              d="M11.78.865L6.513 6.13 1.247.865.005 2.106l5.267 5.267L.005 12.64l1.242 1.241 5.266-5.266 5.267 5.266 1.242-1.241-5.267-5.267 5.267-5.267L11.78.865z"
            ></path>
          </svg>
        ) : (
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default MenuToggle;
