import React from 'react';

const ChevronIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="8"
      fill="none"
      viewBox="0 0 11 8"
    >
      <path
        fill="#0A2533"
        d="M10.913 5.653l-1.4 1.4-4-4-4 4-1.4-1.4 5.4-5.4 5.4 5.4z"
      ></path>
    </svg>
  );
};

export default ChevronIcon;
