import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Logo from 'components/atoms/Logo';
import SocialIcon from 'components/atoms/SocialIcon';
import FooterColumn from 'components/molecules/FooterColumn';
import moment from 'moment';

const Footer: React.FC = () => {
  const {
    customerStories,
    blogs,
    productUpdates,
    events,
    videos,
    downloads,
    reports,
  } = useStaticQuery(graphql`
    query {
      customerStories: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "customer-story" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      blogs: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "blog-post" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      productUpdates: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "product-update" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      events: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "event" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      videos: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "video" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      downloads: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "download" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
      reports: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "report" }
            status: { eq: "public" }
          }
        }
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  `);

  const convenienceRetailLinks = [
    { to: '/convenience-retail', label: 'Overview' },
    { to: '/convenience-retail/scan-data', label: 'Scan Data' },
    { to: '/convenience-retail/engage', label: 'Engage' },
    { to: '/pricing', label: 'Pricing' },
  ];

  const enterpriseBrandsLinks = [
    { to: '/enterprise', label: 'Overview' },
    { to: '/enterprise/insights', label: 'Insights' },
    { to: '/enterprise/engage', label: 'Engage for Brands' },
  ];

  const resourcesLinks = [{ to: '/resources', label: 'All Resources' }];

  if (customerStories.edges.length > 0) {
    resourcesLinks.push({ to: '/customer-stories', label: 'Customer Stories' });
  }

  if (blogs.edges.length > 0) {
    resourcesLinks.push({ to: '/blog', label: 'Blog' });
  }

  if (productUpdates.edges.length > 0) {
    resourcesLinks.push({ to: '/product-updates', label: 'Product Updates' });
  }

  if (events.edges.length > 0) {
    resourcesLinks.push({ to: '/events', label: 'Events' });
  }

  if (videos.edges.length > 0) {
    resourcesLinks.push({ to: '/videos', label: 'Videos' });
  }

  if (downloads.edges.length > 0) {
    resourcesLinks.push({ to: '/downloads', label: 'Downloads' });
  }

  if (reports.edges.length > 0) {
    resourcesLinks.push({ to: '/reports', label: 'Reports' });
  }

  const companyLinks = [
    { to: '/about', label: 'About Us' },
    { to: '/press', label: 'Press' },
    { to: '/careers', label: 'Careers' },
    { to: '/contact', label: 'Contact' },
    { to: '/referrals', label: 'Referral Program' },
    { to: 'https://support.skupos.com/s/', label: 'Help Center' },
  ];

  return (
    <footer className="px-6 lg:px-md pb-24 pt-16 md:py-24 bg-dark-grey relative">
      <div className="u-container mx-auto">
        <div className="flex flex-wrap mb-10 lg:mb-40">
          <div className="w-full mb-12 md:mb-6 lg:flex-3 lg:w-auto">
            <div className="hidden lg:block mb-10">
              <Logo dark />
            </div>
          </div>
          <div className="w-6/12 lg:flex-2 lg:w-auto">
            <FooterColumn
              heading="Convenience Retail"
              links={convenienceRetailLinks}
            />
          </div>
          <div className="w-6/12 lg:flex-2 lg:w-auto">
            <FooterColumn
              heading="Enterprise Brands"
              links={enterpriseBrandsLinks}
            />
          </div>
          <div className="w-6/12 lg:flex-2 lg:w-auto">
            <FooterColumn heading="Resources" links={resourcesLinks} />
          </div>
          <div className="w-6/12 lg:flex-2 lg:w-auto">
            <FooterColumn heading="Company" links={companyLinks} />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between text-xs font-bold text-white uppercase md:flex-row">
          <div className="flex flex-col items-center md:flex-row">
            <div className="md:mr-6">
              © {moment().format('YYYY')} Skupos. All Rights Reserved.{' '}
            </div>
            <div className="flex items-center mt-6 md:mt-0">
              <Link
                to="/terms"
                className="mr-6 transition-opacity hover:opacity-75 duration-150 ease-in-out"
              >
                Terms
              </Link>
              <Link
                to="/privacy"
                className="transition-opacity hover:opacity-75 duration-150 ease-in-out mr-6"
              >
                Privacy
              </Link>
              <a
                className="transition-opacity hover:opacity-75 duration-150 ease-in-out text-10px bg-gray-700 pt-0.75 pb-0.5 px-1.5 rounded"
                href="https://connect.skupos.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Session Key
              </a>
            </div>
          </div>
          <div className="flex items-center mt-6 md:mt-0 space-x-6">
            <SocialIcon url="https://www.facebook.com/skupos" type="facebook" />
            <SocialIcon url="https://twitter.com/skupos" type="twitter" />
            <SocialIcon
              url="https://www.linkedin.com/company/skupos-inc."
              type="linkedin"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
