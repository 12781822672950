import React from 'react';

interface ArrowIconProps {
  className?: string;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ className }) => {
  return (
    <svg
      className={`w-3 h-auto ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
    >
      <path
        fill="currentColor"
        d="M8.108 4.295H.238v1.833h7.87l-2.75 2.75 1.296 1.296 4.315-4.314a.916.916 0 000-1.297L6.654.25 5.358 1.545l2.75 2.75z"
      ></path>
    </svg>
  );
};

export default ArrowIcon;
