import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import SimpleHeader from 'components/organisms/SimpleHeader';
import SimpleFooter from 'components/organisms/SimpleFooter';
import MobileMenu from 'components/organisms/MobileMenu';
import ProductsMenu from 'components/organisms/ProductsMenu';

interface LayoutProps {
  simple?: boolean;
  headerBackground?: string;
  headerInverted?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  simple = false,
  headerBackground,
  headerInverted,
  children,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false);

  return (
    <div className="font-sans antialiased">
      {simple ? (
        <SimpleHeader />
      ) : (
        <Header
          siteTitle={`Skupos`}
          background={headerBackground || 'bg-white'}
          isMobileMenuOpen={mobileMenuOpen}
          inverted={headerInverted}
          onProductsMenuOpen={() => setProductsMenuOpen(true)}
          onMobileMenuToggle={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
      )}

      <AnimatePresence exitBeforeEnter>
        {productsMenuOpen && (
          <ProductsMenu onClose={() => setProductsMenuOpen(false)} />
        )}
        {mobileMenuOpen && (
          <MobileMenu onClose={() => setMobileMenuOpen(false)} />
        )}
      </AnimatePresence>

      <main className="">{children}</main>

      {simple ? <SimpleFooter /> : <Footer />}
    </div>
  );
};

export default Layout;
