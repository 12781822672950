import React from 'react';
import Logo from 'components/atoms/Logo';

interface HeaderProps {
  position?: string;
}

const Header: React.FC<HeaderProps> = ({ position = 'fixed' }) => {
  return (
    <header className={`top-0 left-0 right-0 z-20 bg-transparent ${position}`}>
      <div className="flex items-center justify-center h-20 px-6 border-opacity-10 transition-colors duration-200 bg-white">
        <Logo />
      </div>
    </header>
  );
};

export default Header;
