import { Link } from 'gatsby';
import React, { useState } from 'react';
import cn from 'classnames';
import ArrowIcon from 'components/atoms/ArrowIcon';

interface ProductsNavProps {
  onClick: () => void;
}

const ProductsNav: React.FC<ProductsNavProps> = ({ onClick }) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  return (
    <>
      <div className="flex items-center mb-3 text-xs font-bold uppercase">
        <div className="text-aqua lg:text-dark-grey">Convenience Retail</div>
        <div className="ml-6 h-1.5 bg-aqua flex-grow" />
      </div>
      <ul>
        <li
          className={cn(
            'py-5 border-b border-black border-opacity-10 lg:border-opacity-100 lg:border-light-grey transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 1 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(1)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/convenience-retail"
            className="flex items-center justify-between text-xl font-semibold transition-colors duration-150 ease-in-out"
            onClick={onClick}
          >
            Overview <ArrowIcon />
          </Link>
        </li>
        <li
          className={cn(
            'py-4 transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 2 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(2)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/convenience-retail/scan-data"
            className="flex items-center justify-between transition-colors duration-150 ease-in-out "
            onClick={onClick}
          >
            <div>
              <div className="leading-none text-lg font-semibold mb-0.5">
                Scan Data
              </div>
              <small className="text-sm opacity-50">
                Earn more from brands and customers .
              </small>
            </div>
            <ArrowIcon />
          </Link>
        </li>
        <li
          className={cn(
            'py-4 border-b border-light-grey transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 3 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(3)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/convenience-retail/engage"
            className="flex items-center justify-between transition-colors duration-150 ease-in-out"
            onClick={onClick}
          >
            <div>
              <div className="leading-none text-lg font-semibold mb-0.5">
                Engage
              </div>
              <small className="text-sm opacity-50">
                Unlock exclusive brand promotions.
              </small>
            </div>
            <ArrowIcon />
          </Link>
        </li>
        <li
          className={cn(
            'py-6 transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 4 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(4)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/pricing"
            className="flex items-center justify-between text-lg font-semibold transition-colors duration-150 ease-in-out"
            onClick={onClick}
          >
            <div>Pricing</div>
            <ArrowIcon />
          </Link>
        </li>
      </ul>

      <div className="flex items-center mt-6 mb-3 text-xs font-bold uppercase">
        <div className="text-violet-alt">Enterprise Brands</div>
        <div className="ml-6 h-1.5 bg-violet-alt flex-grow" />
      </div>

      <ul>
        <li
          className={cn(
            'py-5 border-b border-black border-opacity-10 lg:border-opacity-100 lg:border-light-grey transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 5 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(5)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/enterprise"
            className="flex items-center justify-between text-xl font-semibold"
            onClick={onClick}
          >
            Overview <ArrowIcon />
          </Link>
        </li>
        <li
          className={cn(
            'py-4 border-b border-light-grey transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 6 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(6)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/enterprise/insights"
            className="flex items-center justify-between transition-colors duration-150 ease-in-out"
            onClick={onClick}
          >
            <div>
              <div className="leading-none text-lg font-semibold mb-0.5">
                Insights
              </div>
              <small className="text-sm opacity-50">
                Rein in your customer understanding.
              </small>
            </div>
            <ArrowIcon />
          </Link>
        </li>
        <li
          className={cn(
            'py-4 border-b border-light-grey transition-opacity duration-200 ease-in-out',
            hoverIndex !== null && 7 !== hoverIndex
              ? 'opacity-50'
              : 'opacity-100',
          )}
          onMouseEnter={() => setHoverIndex(7)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <Link
            to="/enterprise/engage"
            className="flex items-center justify-between transition-colors duration-150 ease-in-out"
            onClick={onClick}
          >
            <div>
              <div className="leading-none text-lg font-semibold mb-0.5">
                Engage for Brands
              </div>
              <small className="text-sm opacity-50">
                Direct strategic promos across c-stores.
              </small>
            </div>
            <ArrowIcon />
          </Link>
        </li>
      </ul>
    </>
  );
};

export default ProductsNav;