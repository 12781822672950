import React from 'react';

interface NavProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {}

const Nav: React.FC<NavProps> = ({ children, className, ...props }) => {
  return (
    <nav className={`flex items-center  ${className}`} {...props}>
      {children}
    </nav>
  );
};

export default Nav;
