import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import ChevronIcon from 'components/atoms/ChevronIcon';
import SocialIcon from 'components/atoms/SocialIcon';
import ProductsNav from 'components/molecules/ProductsNav';
import Header from './Header';

interface MobileMenuProps {
  onClose: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ onClose }) => {
  const [productsMenuOpen, setProductsMenuOpen] = useState(true);
  const [resourcesMenuOpen, setResourcesMenuOpen] = useState(false);
  const [supportMenuOpen, setSupportMenuOpen] = useState(false);

  // useLockBodyScroll();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="z-10 fixed bottom-0 left-0 right-0 top-0 bg-white min-h-full overflow-y-auto scrollbars-hidden pb-10"
    >
      <Header
        siteTitle="Skupos"
        borderless
        background="bg-white"
        position="relative"
        isMobileMenuOpen={true}
        onMobileMenuToggle={onClose}
      />

      <div className="px-6 pt-6 bg-white z-20 space-y-6">
        <div>
          <button
            className="text-2xl font-semibold w-full flex items-center justify-between text-left h-9 focus:outline-none focus:ring-0"
            onClick={() => setProductsMenuOpen((open) => !open)}
          >
            <div>Products</div>
            <div
              className={`w-9 h-9 rounded-full bg-ghost-grey flex items-center transform justify-center transition-transform duration-300 ${
                productsMenuOpen ? 'rotate-0' : 'rotate-180'
              }`}
            >
              <ChevronIcon />
            </div>
          </button>

          <AnimatePresence>
            {productsMenuOpen && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ type: 'tween', duration: 0.3 }}
                className="bg-tile px-5 py-6 my-6 overflow-hidden"
              >
                <ProductsNav onClick={onClose} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div>
          <Link
            to="/resources"
            className="text-2xl font-semibold w-full flex items-center justify-between text-left h-9 focus:outline-none focus:ring-0"
            onClick={onClose}
          >
            <div>Resources</div>
            {/* <div
              className={`w-9 h-9 rounded-full bg-ghost-grey flex items-center transform justify-center transition-transform duration-300 ${
                resourcesMenuOpen ? 'rotate-0' : 'rotate-180'
              }`}
            >
              <ChevronIcon />
            </div> */}
          </Link>
        </div>
        <div>
          <a
            className="text-2xl font-semibold w-full flex items-center justify-between text-left h-9 focus:outline-none focus:ring-0"
            href="https://support.skupos.com/"
            target="blank"
            rel="noopener noreferrer"
          >
            <div>Help Center</div>
            {/* <div
              className={`w-9 h-9 rounded-full bg-ghost-grey flex items-center transform justify-center transition-transform duration-300 ${
                supportMenuOpen ? 'rotate-0' : 'rotate-180'
              }`}
            >
              <ChevronIcon />
            </div> */}
          </a>
        </div>
      </div>

      <div className="px-6 my-11 space-y-2.5">
        <Link
          className="block leading-none rounded-full text-center py-4 bg-primary text-white font-semibold"
          to="/get-started"
        >
          Get started
        </Link>
        <Link
          className="border leading-none border-gray-300 block rounded-full text-center py-4 font-semibold"
          to="https://go.skupos.com"
        >
          Log in
        </Link>
      </div>

      <div className="flex justify-center items-center mt-6 md:mt-0 space-x-6 mb-9">
        <SocialIcon url="https://www.facebook.com/skupos" type="facebook" />
        <SocialIcon url="https://twitter.com/skupos" type="twitter" />
        <SocialIcon
          url="https://www.linkedin.com/company/skupos-inc."
          type="linkedin"
        />
      </div>

      <p className="text-center px-6 text-xs opacity-20 uppercase font-bold mb-2.5">
        © 2020 Skupos. All Rights Reserved.
      </p>
      <div className="flex items-center justify-center space-x-8 text-xs opacity-20 uppercase font-bold ">
        <Link to="/terms">Terms</Link>
        <Link to="/terms">Privacy</Link>
      </div>
    </motion.div>
  );
};

export default MobileMenu;
