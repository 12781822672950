import React from 'react';
import * as FacebookIcon from 'images/facebook.inline.svg';
import * as InstagramIcon from 'images/instagram.inline.svg';
import * as LinkedInIcon from 'images/linkedin.inline.svg';
import * as TwitterIcon from 'images/twitter.inline.svg';

interface SocialIconProps {
  url: string;
  type: 'twitter' | 'instagram' | 'facebook' | 'linkedin';
  className?: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ url, type, className }) => {
  const iconMapping = {
    facebook: <FacebookIcon className="w-auto h-4 hover:opacity-75 transition-opacity duration-150 ease-in-out" />,
    twitter: <TwitterIcon className="w-auto h-4 hover:opacity-75 transition-opacity duration-150 ease-in-out" />,
    instagram: <InstagramIcon className="w-auto h-4 hover:opacity-75 transition-opacity duration-150 ease-in-out" />,
    linkedin: <LinkedInIcon className="w-auto h-4 hover:opacity-75 transition-opacity duration-150 ease-in-out" />,
  };

  return (
    <a
      className={className}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {iconMapping[type]}
    </a>
  );
};

export default SocialIcon;
