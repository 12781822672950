import { Link } from 'gatsby';
import React from 'react';

interface NavItemProps {
  to: string;
  label: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  className?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  to,
  label,
  onClick,
  onMouseEnter,
  className=''
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!onClick) {
      return true;
    }

    e.preventDefault();
    onClick();
  };

  return (
    <Link
      className={`font-semibold transition-colors hover:text-primary ${className || 'mr-12'}`}
      to={to}
      onMouseEnter={onMouseEnter}
      onClick={handleClick}
    >
      {label}
    </Link>
  );
};

export default NavItem;
