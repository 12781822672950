import { Link } from 'gatsby';
import React from 'react';

interface FooterLink {
  to: string;
  label: string;
}

interface FooterColumnProps {
  heading: string;
  links: FooterLink[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ heading, links }) => {
  return (
    <div className="md:px-4">
      <h2 className="mt-1 mb-4 text-xs font-bold text-white uppercase">
        {heading}
      </h2>

      <ul className="mb-10">
        {links.map(({ to, label }, index) => (
          <li className="mb-3" key={index}>
            <Link
              className="text-sm transition text-light-grey opacity-80 hover:opacity-100 focus:opacity-100 focus:outline-none"
              to={to}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterColumn;
